const aliases = {
    home: {
        page: true,
        name: 'app.root.l1.gh.home'
    },
    deposit: {
        page: false,
        name: 'cashbox',
        private: true
    },
    withdrawal: {
        page: false,
        name: 'cashbox',
        content: { 'tab1': 2, 'tab2': 1 }
    },
    login: {
        page: false,
        name: 'login'
    },
    registration: {
        page: false,
        name: 'registration',
    },
    fastRegistration: {
        page: true,
        name: 'app.root.h1.f1.paynplay'
    },
    promo: {
        page: true,
        name: 'app.root.h1.f1.promo.list'
    },
    promos: {
      page: true,
      name: 'app.root.l1.promo.page',
    },
    realGame: {
        page: true,
        name: 'app.root.game.real'
    },
    history: {
        page: true,
        name: 'app.root.l1.account.history'
    },
    tournaments: {
        page: true,
        name: 'app.root.l1.tournament.list'
    },
    shop: {
        page: true,
        name: 'app.root.l1.shop',
    },
    profile: {
        page: true,
        name: 'app.root.h1.f1.account.info'
    },
    paynplay: {
        page: true,
        name: 'app.root.l1.paynplay'
    },
    gameHall: {
        page: true,
        name: 'app.root.h1.casino.hall'
    },
    collection: {
        page: true,
        name: 'app.root.h1.f1.collection.list'
    },
    bet: {
        page: true,
        name: 'app.root.l1.sportpage.live'
    },

    sport: {
        page: true,
        name: 'app.root.l1.sportpage.prelive'
    },
    live: {
        page: true,
        name: 'app.root.l1.gh.live'
    },
    games: {
        page: true,
        name: 'app.root.h1.casino.hall'
    },
    missedData: {
        page: true,
        name: 'app.root.missedData'
    },
    soccer: {
      page: true,
      name: 'app.root.l1.sportpage.prelive',
      params:{
        sportids: '66'
      },
    },
    tennis: {
      page: true,
      name: 'app.root.l1.sportpage.prelive',
      params:{
        sportids: '68'
      },
    },

    cash_crab: {
      page: true,
      name: 'app.root.claw_games.gamepage',
      params: {
        name: 'cash_crab'
      }
    },
    bonus_crab: {
      page: true,
      name:'app.root.claw_games.gamepage',
      params: {
        name:'cash-crab'
      }
    },
    claw: {
      page: true,
      name: 'app.root.claw_games.gamepage'
    },
    terms: {
      page: true,
      name: 'app.root.l1.info.terms'
    },
    evolution: {
        page: true,
        name: "app.root.l1.gh.provider",
        params: {
          name: 'evolution'
        }
    },
    pragmatic: {
        page: true,
        name: "app.root.l1.gh.provider",
        params: {
          name: 'pragmatic'
        }
    },
    playngo: {
        page: true,
        name: "app.root.l1.gh.provider",
        params: {
          name: 'playngo'
        }
    },
    halloween: {
        page: true,
        name: "app.root.l1.tournament.page",
        params: {
          name: 'halloween'
        }
      },
    christmasTournament: {
        page: true,
        name: "app.root.l1.tournament.page",
        params: {
          name: 'party-raffle'
      }
    },
    threeoaks: {
        page: true,
        name: "app.root.l1.gh.provider",
        params: {
          name: 'threeoaks'
        }
    },
    spinomenal: {
        page: true,
        name: "app.root.l1.gh.provider",
        params: {
          name: 'spinomenal'
        }
    },
    ela: {
        page: true,
        name: "app.root.l1.gh.provider",
        params: {
          name: 'ela'
        }
    },
    relax: {
      page: true,
      name: "app.root.l1.gh.provider",
      params: {
        name: 'relax'
      }
    },
    skywind: {
      page: true,
      name: "app.root.l1.gh.provider",
      params: {
        name: 'skywind'
      }
    },
    netent: {
      page: true,
      name: "app.root.l1.gh.provider",
      params: {
        name: 'netent'
      }
    },
    pushgaming: {
      page: true,
      name: "app.root.l1.gh.provider",
      params: {
        name: 'pushgaming'
      }
    },
    playtech: {
      page: true,
      name: "app.root.l1.gh.provider",
      params: {
        name: 'playtech'
      }
    },
    quickspin: {
      page: true,
      name: "app.root.l1.gh.provider",
      params: {
        name: 'quickspin'
      }
    },
    redtiger: {
      page: true,
      name: "app.root.l1.gh.provider",
      params: {
        name: 'redtiger'
      }
    },
    promotions: {
      page: true,
      name: "app.root.l1.promo.list"
    },
    summer: {
      page: true,
      name: 'app.root.seasons',
    },
    summerGame: {
      page: true,
      name: 'app.root.summer-game',
    },

    gameProvider: {
        page: true,
        name: 'app.root.l1.gh.provider.hall',
    },
    oktoberfest: {
        page: true,
        name: 'app.root.claw_games.gamepage',
        params: {
            name: 'oktoberfest',
        },
    },
};

export { aliases };
