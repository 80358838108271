(function() {
    'use strict';

    const directive = { name: 'resetFilter' };

    controller.$inject = ['$location', '$state'];

    function controller($location, $state) {
        function link(scope, element, attrs) {
            scope.$watchCollection('collection', function() {
                if (scope.collection.length > 25) {
                    scope.activeFilter = null;
                }
            });
        }

        return {
            restrict: 'A',
            link,
        };
    }

    app.directive(directive.name, controller);
})();