const router = [{
        name: 'app.root',
        abstract: true,
        template: '<linda-app linda-livechatinc></linda-app>'
            // template: '<linda-app linda-freshchat ></linda-app>'
    },

    {
        name: 'app.root.game',
        abstract: true,
        template: '<linda-game-page></linda-game-page>',
        gamePage: true
    },
    {
        name: 'app.root.game.real',
        url: '/play/:name',
        template: '',
        private: true,
        params: {
            mode: 'real'
        }
    },
    {
        name: 'app.root.game.demo',
        url: '/game/:name',
        template: '<linda-demo-game-footer  game="game"></linda-demo-game-footer>',
        params: {
            mode: 'demo'
        }
    },

    {
        name: 'app.root.claw_games',
        abstract: true,
        private: true,
        template: '<ui-view></ui-view>'
    },
    {
        name: 'app.root.claw_games.gamepage',
        url: '/machine/:name',
        template: '<batman-view-layer1 />'
    },

    {
        name: 'app.root.l1',
        abstract: true,
        template: '<linda-view-layer-one></linda-view-layer-one>'
    },

    {
        name: 'app.root.l1.gh',
        abstract: true,
        template: '<linda-view-layer-three></linda-view-layer-three>'
    },
    {
        name: 'app.root.l1.gh.home',
        url: '/',
        views: {
            header: {
                template: '<linda-view-layer-five scroll-to-top></linda-view-layer-five>'
            },
            content: {
                template: '<linda-homepage scroll-to-top></linda-homepage>'
            }
        }
    },

    // Providers
    // {
    //     name: 'app.root.l1.gh.casino.provider',
    //     url: '/casino/providers/:name',
    //     template: `<batman-game-hall2 scroll-to-top></batman-game-hall2>`,
    //     params: {
    //         collection: 'provider'
    //     }
    // },
    {
        name: 'app.root.l1.gh.providers',
        url: '/providers',
        template: '<batman-provider-list1></batman-provider-list1>',
        params: {
            games: true,
        },
    },
    {
        name: 'app.root.l1.gh.provider',
        url: '/providers/:name',
        views: {
            header: {
                template: '<batman-game-hall6 scroll-to-top></batman-game-hall6>'
            },
            content: {
                template: `<batman-game-hall2 scroll-to-top></batman-game-hall2>`
            }
        },
        params: {
            games: true,
        },
    },

    {
        name: 'app.root.l1.gh.favourites',
        url: '/favourites',
        views: {
            header: {
                template: '<batman-game-hall7 scroll-to-top></batman-game-hall7>'
            },
            content: {
                template: '<batman-game-box-favourites1 scroll-to-top></batman-game-box-favourites1>'
            }
        },
        params: {
            page: 'favourites'
        }
    },

    //promo
    {
        name: 'app.root.l1.promo',
        abstract: true,
        template: '<ui-view></ui-view>'
    },
    {
        name: 'app.root.l1.promo.list',
        url: '/promotions',
        template: '<linda-promos scroll-to-top></linda-promos>',
    },
    {
        name: 'app.root.l1.promo.page',
        url: '/promotions/:name',
        template: '<linda-promo-page scroll-to-top></linda-promo-page>'
    },
    {
        name: 'app.root.l1.halloween',
        url: '/seasons/halloween',
        template: '<halloween-promo class="halloween-unset" scroll-to-top="" ></halloween-promo>'
    },

    //sport
    {
        name: 'app.root.l1.sportpage',
        abstract: true,
        template: '<linda-sport-page></linda-sport-page>'
    },
    {
        name: 'app.root.l1.sportpage.prelive',
        url: '/sport?sportids&catids&champids&tabs&eventid&betslipoddids',
        template: '',
        params: {
            page: 'prelive'
        }
    },
    {
        name: 'app.root.l1.sportpage.live',
        url: '/sport/live?sportids&catids&champids&tabs&eventid&betslipoddids',
        template: '',
        params: {
            page: 'live'
        }
    },
    {
        name: 'app.root.l1.sportpage.virtual',
        url: '/sport/virtual?sportids&catids&champids&tabs&eventid&betslipoddids',
        template: '',
        params: {
            page: 'vflm'
        }
    },
    {
        name: 'app.root.l1.sportpage.mybets',
        url: '/sport/mybets',
        template: '',
        params: {
            page: 'mybets'
        }
    },

    //tournment
    {
        name: 'app.root.l1.tournament',
        abstract: true,
        template: '<ui-view></ui-view>'
    },
    {
        name: 'app.root.l1.tournament.list',
        url: '/tournaments',
        template: '<linda-tournaments scroll-to-top></linda-tournaments>'
    },
    {
        name: 'app.root.l1.tournament.page',
        url: '/tournaments/:name',
        template: '<linda-tournament-page scroll-to-top></linda-tournament-page>'
    },

    //vip
    {
        name: 'app.root.l1.vip',
        url: '/vip',
        template: '<linda-vip scroll-to-top></linda-vip>'
    },

    //USER
    {
        name: 'app.root.l1.account',
        abstract: true,
        private: true,
        template: '<linda-profile-page scroll-to-top></linda-profile-page>'
    },
    {
        name: 'app.root.l1.account.info',
        url: '/account',
        template: '<linda-profile-settings></linda-profile-settings>'

    },
    {
        name: 'app.root.l1.account.bonuses',
        url: '/account/your-bonuses',
        template: '<linda-profile-bonus-list></linda-profile-bonus-list>'
    },
    {
        name: 'app.root.l1.account.history',
        url: '/account/balance-history',
        template: '<linda-cashbox-balance-information></linda-cashbox-balance-information>'
    },
    {
        name: 'app.root.l1.account.verification',
        url: '/account/verification',
        template: '<dvs-page  ></dvs-page>'
    },

    {
        name: 'app.root.paymentcb',
        url: '/payment/:act?order_id&identifier',
        template: '<linda-payment-callback></linda-payment-callback>'
    },

    {
        name: 'app.root.withdraw-paymentcb-success',
        url: '/withdraw-payment/success',
        template: '<linda-withdrawal-payment-callback></linda-withdrawal-payment-callback>',
        params: {
            status: 'success'
        }
    },
    {
        name: 'app.root.withdraw-paymentcb-fail',
        url: '/withdraw-payment/fail',
        template: '<linda-withdrawal-payment-callback></linda-withdrawal-payment-callback>',
        params: {
            status: 'fail'
        }
    },

    // INFO PAGES
    {
        name: 'app.root.l1.info',
        abstract: true,
        template: '<linda-view-layer-two></linda-view-layer-two>'
    },
    {
        name: 'app.root.l1.info.sitemap',
        url: '/sitemap',
        template: '<linda-sitemap scroll-to-top></linda-sitemap>',
        params: {
            name: 'sitemap'
        }
    },
    {
        name: 'app.root.l1.info.privacy-policy',
        url: '/privacy-policy',
        template: '<linda-info-page scroll-to-top></linda-info-page>',
        params: {
            pageName: 'info',
            name: 'privacy-policy'
        }
    },
    {
        name: 'app.root.l1.info.about',
        url: '/about-us',
        template: '<linda-info-page scroll-to-top></linda-info-page>',
        params: {
            pageName: 'info',
            name: 'about'
        }
    },
    {
        name: 'app.root.l1.info.shortcut',
        url: '/shortcut',
        template: '<linda-info-page scroll-to-top></linda-info-page>',
        params: {
            pageName: 'info',
            name: 'shortcut'
        }
    },

    // Cookie
    {
        name: 'app.root.l1.info.cookies-policy',
        url: '/cookies-policy',
        template: '<linda-info-page scroll-to-top></linda-info-page>',
        params: {
            pageName: 'info',
            name: 'cookies-policy',
        }
    },
    {
        name: 'app.root.l1.info.contact',
        url: '/contact-us',
        template: '<linda-info-page scroll-to-top></linda-info-page>',
        params: {
            pageName: 'info',
            name: 'contact-us'
        }
    },
    {
        name: 'app.root.l1.info.responsible-gaming',
        url: '/responsible-gaming',
        template: '<linda-info-page scroll-to-top></linda-info-page>',
        params: {
            pageName: 'info',
            name: 'responsible-gaming'
        }
    },
    {
        name: 'app.root.l1.info.terms',
        url: '/rules',
        template: '<linda-info-page scroll-to-top></linda-info-page>',
        params: {
            pageName: 'info',
            name: 'rules'
        }
    },
    {
        name: 'app.root.l1.info.faq',
        url: '/faq',
        template: '<linda-info-page scroll-to-top></linda-info-page>',
        params: {
            pageName: 'info',
            name: 'faq'
        }
    },
    {
        name: 'app.root.l1.info.complaint',
        url: '/complaint',
        template: '<linda-info-page scroll-to-top></linda-info-page>',
        params: {
            pageName: 'info',
            name: 'complaint'
        }
    },

    {
        name: 'app.root.l1.paynplay',
        url: '/fast-deposit',
        template: '<linda-zimpler-paynplay-page></linda-zimpler-paynplay-page>'
    },

    {
        name: 'app.root.404',
        url: '/404',
        template: '<not-found></not-found>'
    },

    // - MISSED DATA
    {
        name: 'app.root.missedData',
        url: '/missed-data',
        template: '<linda-missed-data-form><linda-missed-data-form/>'
    },
    {
        name: 'app.root.seasons',
        url: '/seasons/summer',
        private: true,
        template: '<summer-promo-wrapper scroll-to-top="" class="seasons-promo-page"></summer-promo-wrapper>'
    },
    {
        name: 'app.root.summer-game',
        url: '/seasons-summer-game',
        private: true,
        template: `<summer-promo-page scroll-to-top=""></summer-promo-page>`,
    },
    {
        name: 'app.root.l1.shop',
        url: '/shop',
        template: '<linda-shop-page scroll-to-top></linda-shop-page>'
    },

    {
        name: 'app.root.l1.gh.collection',
        url: '/:collection',
        views: {
            header: {
                template: '<batman-game-hall4></batman-game-hall4>'
            },
            content: {
                template: '<batman-game-hall5 scroll-to-top></batman-game-hall5>'
            }
        },
        collection: 'games',
        onEnter: ['casinoResolver', function(casinoResolver) {
            casinoResolver.collection();
        }]
    },
    {
        name: 'app.root.l1.gh.category',
        url: '/:collection/:name',
        views: {
            header: {
                template: '<batman-game-hall4 scroll-to-top></batman-game-hall4>'
            },
            content: {
                template: '<batman-game-hall1 scroll-to-top></batman-game-hall1>'
            }
        },
        params: {
            collection: 'games',
            name: 'top',
        },
        onEnter: ['casinoResolver', function(casinoResolver) {
            casinoResolver.collection();
        }]
    },
];

export { router }