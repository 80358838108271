import '../domain/http.js';
import '../localStorage.js';

export const serviceName = 'affilate';

class Affilate {
  static $inject = ['http', 'localStorage'];

  constructor(http, localStorage) {
    this.http = http;
    this.localStorage = localStorage;
  }

  storedData = {};

  init() {
    const affParams = this.localStorage.getAffiliateData();
    return this.getData(affParams);
  }

  getData(params) {
    return this.http.getData('affiliateInfo', params).then(({ result }) => {
      this.storedData = result;
    });
  }
}

app.service(serviceName, Affilate);
