(function(){

    'use strict';

    const directive = { name: 'scrollToTopOnClickPr' };

    controller.$inject = [ ];

    function controller( ){

        function link( scope, element, attrs ){


            const config = scope.$eval( attrs[directive.name] ) || {};


            $( element ).on( 'click', e => {
                $( config.selector || 'html, body' ).animate({ scrollTop: 460 }, 400);
            });

        }

        return {
            restrict: 'A',
            link
        };
    }

    app.directive(  directive.name, controller );

})();

