//- All property styles you can check on this page:
//- https://sb2widgetsstatic-altenar2-stage.biahosted.com/docs/?path=/docs/widgets-tokens--page
//- On this page you can try constructor styles:
//- https://sb2widgetsstatic-altenar2-stage.biahosted.com/docs/?path=/story/widgets-wtabbedeventsbytype--live-now
const $_ALTENARCONFIG = {}
let highlights = {
  "OddBox": {
    "borderWidth": 0,
    "borderColor": "#E9EBF7",
    "borderStyle": "solid",
    "borderRadius": 0,
    "background": "#fff",
    "paddingVertical": 10,
    "paddingHorizontal": 16,
    "oddFont": [
      "Roboto",
      14,
      "normal",
      900,
      "normal"
    ],
    "labelFont": [
      "Roboto",
      14,
      "normal",
      400,
      "normal"
    ],
    "oddColor": "#05207B",
    "labelColor": "rgba(0, 0, 0, 0.54)",
    "arrowUp": [
      24,
      24,
      "#4CAF50"
    ],
    "arrowDown": [
      24,
      24,
      "#F44336"
    ]
  },
  "OddBoxSelected": {
    "borderColor": "#fff",
    "background": "#05207B",
    "oddColor": "#FFFFFF",
    "labelColor": "#FFFFFF"
  },
  "MarketBox": {
    "width": "100%",
    "spacing": 8
  },
  "EventBox": {
    "borderWidth": 1,
    "borderColor": "#E9EBF7",
    "borderStyle": "solid",
    "borderRadius": 0,
    "background": "#FFFFFF",
    "paddingVertical": 12,
    "paddingHorizontal": 12,
    "liveTimeFont": [
      "Montserrat",
      14,
      "normal",
      500,
      "21px"
    ],
    "dateTimeFont": [
      "Montserrat",
      12,
      "normal",
      600,
      "20px"
    ],
    "categoryChampionshipFont": [
      "Montserrat",
      12,
      "normal",
      600,
      "20px"
    ],
    "competitorFont": [
      "Montserrat",
      14,
      "normal",
      700,
      "21px"
    ],
    "scoreFont": [
      "Montserrat",
      14,
      "normal",
      700,
      "21px"
    ],
    "liveTimeColor": "#65697B",
    "dateTimeColor": "#FD4558",
    "categoryChampionshipColor": "rgba(0, 0, 0, 0.54)",
    "competitorColor": "rgba(0, 0, 0, 0.87)",
    "scoreColor": "rgba(0, 0, 0, 0.87)"
  },
  "SportTab": {
    "borderWidth": 0,
    "borderColor": "unset",
    "borderStyle": "none",
    "borderRadius": 0,
    "font": [
      "proxima-nova,sans-serif",
      16,
      "normal",
      700,
      "14px"
    ],
    "background": "#fff",
    "color": "#05207B",
    "paddingHorizontal": 12,
    "paddingVertical": 10,
    "spacing": 16,
    "textTransform": "uppercase"
  },
  "SportTabSelected": {
    "font": [
      "proxima-nova,sans-serif",
      16,
      "normal",
      700,
      "14px"
    ],
    "indicatorColor": "#fff",
    "borderColor": "#fff",
    "color": "#fff",
    "background": "#05207B",
    "textTransform": "uppercase",
  },
  "TabbedTopSportEvents": {
    "borderWidth": 1,
    "borderColor": "#E9EBF7",
    "borderStyle": "solid",
    "borderRadius": 0,
    "paddingHorizontal": 16,
    "paddingVertical": 16,
    "background": "#fff",
    "titleColor": "#05207B",
    "titleFont": [
      "Roboto",
      18,
      "italic",
      900,
      "40px",
    ],
    "titleTextTransform": "uppercase"
  },
  "EventList": {
    "variant": 1,
    "rowSpacing": 8,
    "columnSpacing": 16
  },
  "LiveIndicator": {
    "variant" : 0,
    "borderWidth": 0,
    "borderColor": "unset",
    "borderStyle": "none",
    "font": [
      "Montserrat",
      14,
      "normal",
      500,
      "21px"
    ],
    "textTransform" : "uppercase",
    "background" : "#d32f2f",
    "color" : "#fff",
    "borderRadius" : 4,
    "paddingHorizontal" : 4,
    "paddingVertical" : 0,
    "clipPath" : "unset",
  }
}

let liveNow = highlights;

let carousel = {
  "OddBox": {
    "borderWidth": 1,
    "borderColor": "#292C32",
    "borderRadius": 100,
    "variant": 0,
    "spacing": 4,
    "background": "#17191E",
    "paddingVertical": 4,
    "paddingHorizontal": 11,
    "oddFont": [
      "Onest",
      12,
      "normal",
      700,
      "normal"
    ],
    "labelFont": [
      "Onest",
      12,
      "normal",
      700,
      "normal"
    ],
    "oddColor": "#fff",
    "labelColor": "#78788C",
    "arrowUp": [
      24,
      24,
      "#4CAF50"
    ],
    "arrowDown": [
      24,
      24,
      "#F44336"
    ]
  },
  "OddBoxSelected": {
    "borderColor": "#3E5EBB",
    "background": "#3E5EBB",
    "oddColor": "#E0E0E0",
    "labelColor": "#E0E0E0"
  },
  "BannerEventBox": {
    "variant": 0,
    "width": 325,
    "borderWidth": 0,
    "borderColor": "none",
    "borderRadius": 10,
    "background": "#17191E",
    "paddingVertical": 20,
    "paddingHorizontal": 20,
    "timeFont": [
      "Montserrat",
      18,
      "normal",
      600,
      "28px"
    ],
    "dateFont": [
      "Montserrat",
      10,
      "normal",
      700,
      "16px"
    ],
    "championshipFont": [
      "Montserrat",
      10,
      "normal",
      700,
      "16px"
    ],
    "competitorFont": [
      "Montserrat",
      14,
      "normal",
      700,
      "18px"
    ],
    "messageBodyFont": [
      "Roboto",
      16,
      "normal",
      500,
      "24px"
    ],
    "messageHeaderFont": [
      "Roboto",
      16,
      "normal",
      500,
      "24px"
    ],
    "timeColor": "#fff",
    "dateColor": "#78788C",
    "championshipColor": "#78788C",
    "competitorColor": "#FFCD34",
    "messageHeaderColor": "#15C187",
    "messageBodyColor": "rgba(0, 0, 0, 0.87)",
    "dividerColor": "rgba(0, 0, 0, 0.12)",
    "dividerWidth": 1,
    "dividerHeight": "85%"
  },
  "MarketBox": {
    "font": [
      "Roboto",
      12,
      "normal",
      500,
      "21px"
    ],
    "color": "rgba(0, 0, 0, 0.54)",
    "spacing": 8
  },
  "CompetitorLogo": {
    "height": 64,
    "width": 64,
    "background": "#0E1932",
    "borderRadius": "50%",
    "borderColor": "#3E5EBB",
    "borderWidth": 2,
    "offset": -8
  },
  "EventsCarousel": {
    "borderWidth": 0,
    "borderColor": "unset",
    "borderRadius": 0,
    "width": "unset",
    "paddingHorizontal": 0,
    "paddingVertical": 24,
    "spacing": 12
  },
  "EventsCarouselControls": {
    "borderWidth": 0,
    "borderColor": "none",
    "borderRadius": 10,
    "dotBackground": "none",
    "selectedDotBackground": "none",
    "background": "#4f7fff",
    "color": "#fff",
    "height": 0,
    "width": 0,
  },
};
$_ALTENARCONFIG.widget = {
  tokens: {
    liveNow,
    highlights
  },
  carousel
};

export {$_ALTENARCONFIG};
