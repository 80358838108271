import '../../service/domain/user.js';
import '../../service/domain/http.js';
import '../../service/configs/config.js';
import '../../service/domain/state.js';
import '../../service/domain/popup.js';
import '../../service/domain/beacon-api.js';
import '../../service/analytics/analytics.js';
import '../../service/recaptcha.js';
import '../../service/configs/analytic-events.js';
import '../analytics/heatmap.js';
import '../../service/localStorage.js';
import '../../service/domain/sentry.js';
import '../../service/domain/affilate.js'

(function () {
  'use strict';

  controller.$inject = [
    'user',
    'http',
    '$q',
    'config',
    'state',
    'popup',
    'beaconAPI',
    'analytics',
    'recaptcha',
    'analyticsEvents',
    'heatmap',
    'localStorage',
    'sentry',
    'affilate',
    '$cookies'
  ];

  function controller(
    _user,
    _http,
    $q,
    _config,
    _state,
    _popup,
    beaconAPI,
    analytics,
    _recaptcha,
    _analyticsEvents,
    _heatmap,
    _localStorage,
    sentry,
    affilate,
    $cookies,
  ) {
    const getAdFormCookieID = () => window.Adform?.['_uid'];

    return {
      register(credentials) {
        const defer = $q.defer();
        const alias = 'userRegister';
        const config = {
          meta: { alias },
        };
        const ls = _localStorage.getAffiliateData();
        const data = Object.assign({}, ls, credentials);
        data.product = _config.product;

        const fbpValue = $cookies.get('_fbp');

        if (fbpValue) {
          data.fbp = fbpValue;
        }

        const adformcookieid = getAdFormCookieID();
        if (adformcookieid) {
          data.adformcookieid = adformcookieid;
        }
        _recaptcha
          .execute()
          .then((recaptchaToken) => {
            Object.assign(data, { 'g-recaptcha-response': recaptchaToken });
          })
          .catch((error) => error)
          .finally(() => {
            _http.post(alias, data, config).then(
              (answer) => {
                analytics.send(_analyticsEvents.registrationSuccess, 'success', answer.data.result.login);
                _user.profile = answer.data.result;
                _heatmap.sendEvent('event', 'register');
                _heatmap.sendEvent('identify', _user.profile.account_id);
                defer.resolve(answer.data);
              },
              (answer) => {
                analytics.send(
                  _analyticsEvents.registrationFail,
                  answer.data.messages[0].code?.toString(),
                  'Not_Login',
                );
                defer.reject(answer.data);
              },
            );
          });

        return defer.promise;
      },

      login(data) {
        const defer = $q.defer();
        const alias = 'userLogin';
        const config = {
          meta: { alias },
        };

        const adformcookieid = getAdFormCookieID();
        if (adformcookieid) {
          data.adformcookieid = adformcookieid;
        }

        _recaptcha
          .execute()
          .then((recaptchaToken) => {
            data = Object.assign(data, { 'g-recaptcha-response': recaptchaToken });
          })
          .catch((error) => error)
          .finally(() => {
            _http.post(alias, data, config).then(
              (answer) => {
                _user.profile = answer.data.result;
                analytics.send(_analyticsEvents.loginSuccess, 'success', data.login);
                _heatmap.sendEvent('event', 'login');
                _heatmap.sendEvent('identify', _user.profile.account_id);
                defer.resolve(answer.data);
                const sentryData = {
                  level: 'info',
                  tags: {
                    event: 'login',
                    type: 'success',
                    account_id: _user.profile.account_id,
                  },
                };
                sentry.sendMessage('login success', sentryData);
                affilate.getData();
              },
              (answer) => {
                analytics.send(_analyticsEvents.loginFail, answer.data.messages[0].code?.toString(), 'Not_Login');
                sentry.sendException('login fail', {
                  tags: {
                    event: 'login',
                    type: 'error',
                    status: answer.status,
                  },
                  extra: {
                    message: JSON.stringify(answer.data.messages[0]),
                  },
                });
                defer.reject(answer.data);
              },
            );
          });

        return defer.promise;
      },

      logout(isFrontEndSideLogout) {
        const defer = $q.defer();
        const alias = 'userLogout';
        const config = {
          meta: { alias },
        };
        if (isFrontEndSideLogout === true) {
          sentry.sendMessage('logout success', {
            level: 'info',
            tags: {
              event: 'logout',
              type: 'success',
              side: 'frontend',
              account_id: _user.profile.account_id,
            },
          });
          finishSession();
          defer.resolve();
        } else {
          _http
            .get(alias, config)
            .then(() => {
              finishSession();
              defer.resolve();
              sentry.sendMessage('logout success', {
                level: 'info',
                tags: {
                  event: 'logout',
                  type: 'success',
                  side: 'backend',
                },
              });
            })
            .catch((error) => {
              sentry.sendException('logout fail', {
                tags: {
                  event: 'logout',
                  type: 'error',
                },
                extra: {
                  message: JSON.stringify(error.data.messages[0]),
                },
              });
              defer.reject();
            });
        }

        function finishSession() {
          _user.profile = {};
          _localStorage.remove('accountId');
          _popup.close();
          _state.goto('home');
        }

        return defer.promise;
      },

      info(options) {
        const defer = $q.defer();
        const alias = 'userInfo';
        const config = {
          params: options,
          meta: { alias },
        };

        _http.get(alias, config).then(
          (answer) => {
            _user.profile = answer.data.result;
            defer.resolve();
          },
          (answer) => {
            _user.profile = {};
            defer.reject();
          },
        );

        return defer.promise;
      },

      changePassword(data) {
        const defer = $q.defer();
        const alias = 'userChangePass';
        const config = {
          meta: { alias },
        };

        _http.put(alias, data, config).then(
          (answer) => {
            defer.resolve(answer);
          },
          (answer) => {
            defer.reject(answer);
          },
        );

        return defer.promise;
      },

      update(data) {
        const defer = $q.defer();
        const alias = 'userEdit';
        const config = {
          meta: { alias },
        };

        _http.put(alias, data, config).then(
          (answer) => {
            _user.profile = answer.data.result;
            defer.resolve(answer);
          },
          (answer) => {
            defer.reject(answer);
          },
        );

        return defer.promise;
      },

      restore(data) {
        const defer = $q.defer();
        const alias = 'userRestore';
        const config = {
          meta: { alias },
        };

        _http.post(alias, data, config).then(
          (answer) => {
            analytics.send(_analyticsEvents.recoveryRequestSuccess, 'success');
            defer.resolve();
          },
          (answer) => {
            analytics.send(_analyticsEvents.recoveryRequestFail, answer.data.messages[0].code?.toString());
            defer.reject();
          },
        );

        return defer.promise;
      },

      activationCode(data) {
        const defer = $q.defer();
        const alias = 'userResendActivationCode';
        const config = {
          meta: { alias },
        };

        _http.post(alias, data, config).then(
          (answer) => {
            defer.resolve(answer);
          },
          (answer) => {
            defer.reject(answer);
          },
        );

        return defer.promise;
      },

      activate(data) {
        const defer = $q.defer();
        const alias = 'userActivateMail';
        const config = {
          meta: { alias },
        };

        _http.post(alias, data, config).then(
          (answer) => {
            _user.profile = answer.data.result;
            defer.resolve(answer);
          },
          (answer) => {
            defer.reject(answer);
          },
        );

        return defer.promise;
      },

      restorationChangePassword(data) {
        const defer = $q.defer();
        const alias = 'userRestorePassword';
        const config = {
          meta: { alias },
        };

        _http.put(alias, data, config).then(
          (answer) => {
            defer.resolve(answer);
          },
          (answer) => {
            defer.reject(answer);
          },
        );

        return defer.promise;
      },

      autobonuses(options) {
        const defer = $q.defer();
        const alias = 'userAvBonus';
        const config = {
          params: options,
          meta: { alias },
        };

        _http.get(alias, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          },
        );

        return defer.promise;
      },

      limits(data) {
        const defer = $q.defer();
        const alias = 'userChangeLimit';
        const config = {
          meta: { alias },
        };

        _http.put(alias, data, config).then(
          (answer) => {
            _user.profile = answer.data.result;
            defer.resolve(answer);
          },
          (answer) => {
            defer.reject(answer);
          },
        );

        return defer.promise;
      },

      removeLimit(data) {
        const defer = $q.defer();
        const alias = 'userRemoveLimit';
        const config = {
          meta: { alias },
        };

        _http.post(alias, data, config).then(
          (answer) => {
            defer.resolve(answer);
          },
          (answer) => {
            defer.reject(answer);
          },
        );

        return defer.promise;
      },

      cancelLimit(data) {
        const defer = $q.defer();
        const alias = 'userChangeLimit';
        const config = {
          meta: { alias },
          data,
        };

        _http.delete(alias, config).then(
          (answer) => {
            _user.profile = answer.data.result;
            defer.resolve(answer);
          },
          (answer) => {
            defer.reject(answer);
          },
        );

        return defer.promise;
      },

      sessionLength(data) {
        const defer = $q.defer();
        const alias = 'sessionLength';
        const config = {
          meta: { alias },
        };

        _http.put(alias, data, config).then(
          (answer) => {
            _user.profile = answer.data.result;
            defer.resolve(answer);
          },
          (answer) => {
            defer.reject(answer);
          },
        );

        return defer.promise;
      },

      exclude(data) {
        const defer = $q.defer();
        const alias = 'userExclusion';
        const config = {
          meta: { alias },
        };

        switch (data.action) {
          case 'BLOCK':
            data = {
              action: data.action,
              expires: data.expires,
            };
            break;

          case 'EXCLUDE':
            data = {
              action: data.action,
              exclude: data.exclude,
            };
            break;

          default:
            break;
        }

        _http.post(alias, data, config).then(
          (answer) => {
            _user.profile = {};
            defer.resolve(answer);
          },
          (answer) => {
            defer.reject(answer);
          },
        );

        return defer.promise;
      },

      close(data) {
        const defer = $q.defer();
        const alias = 'userClosure';
        const config = {
          meta: { alias },
        };

        _http.post(alias, data, config).then(
          // FET-3702
          // answer => { _user.profile = {}; defer.resolve( answer ) },
          (answer) => {
            defer.resolve(answer);
          },
          (answer) => {
            defer.reject(answer);
          },
        );

        return defer.promise;
      },

      win(data) {
        const defer = $q.defer();
        const alias = 'userWin';
        const config = {
          params: data,
          meta: { alias },
        };

        _http.get(alias, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          },
        );

        return defer.promise;
      },

      svTokenRestore(data) {
        const defer = $q.defer();
        const alias = 'userSVTokenRestore';
        const config = {
          meta: { alias },
        };

        _http.post(alias, data, config).then(
          (answer) => {
            defer.resolve(answer);
          },
          (answer) => {
            defer.reject(answer);
          },
        );

        return defer.promise;
      },

      svRealitySettings(data) {
        const defer = $q.defer();
        const alias = 'userSVRealitySettings';
        const config = {
          meta: { alias },
        };

        _http.put(alias, data, config).then(
          (answer) => {
            _user.profile = answer.data.result;
            defer.resolve(answer);
          },
          (answer) => {
            defer.reject(answer);
          },
        );

        return defer.promise;
      },

      changeMask(data) {
        const defer = $q.defer();
        const alias = 'userSetMask';
        const config = {
          meta: { alias },
        };

        _http.post(alias, data, config).then(
          (answer) => {
            _user.profile = answer.data.result;
            defer.resolve(answer);
          },
          (answer) => {
            defer.reject(answer);
          },
        );

        return defer.promise;
      },

      multiLimits(data) {
        const defer = $q.defer();
        const alias = 'userMultiLimits';
        const config = {
          meta: { alias },
        };

        _http.put(alias, data, config).then(
          (answer) => {
            _user.profile = answer.data.result;
            defer.resolve(answer);
          },
          (answer) => {
            defer.reject(answer);
          },
        );

        return defer.promise;
      },

      lastDeposit(data) {
        const defer = $q.defer();
        const alias = 'historyLastDep';
        const config = {
          params: data,
          meta: { alias },
        };
        _http.get(alias, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          },
        );
        return defer.promise;
      },

      acceptNewTerms(data) {
        const defer = $q.defer();
        const alias = 'userTermsChanged';
        const config = {
          meta: { alias },
        };

        _http.post(alias, data, config).then(
          (answer) => {
            _user.profile = answer.data.result;
            defer.resolve(answer);
          },
          (answer) => {
            defer.reject(answer);
          },
        );

        return defer.promise;
      },

      refreshSession(data) {
        const defer = $q.defer();
        const alias = 'userRefreshSession';
        const config = {
          meta: { alias },
        };

        _http.post(alias, data, config).then(
          (answer) => {
            _user.profile = answer.data.result;
            defer.resolve(answer);
          },
          (answer) => {
            defer.reject(answer);
          },
        );

        return defer.promise;
      },

      recoveryLogin(data) {
        const defer = $q.defer();
        const alias = 'userRecoveryLogin';
        const config = {
          meta: { alias },
        };

        _http.post(alias, data, config).then(
          (answer) => {
            defer.resolve();
          },
          (answer) => {
            defer.reject();
          },
        );

        return defer.promise;
      },

      missedFields(data) {
        const defer = $q.defer();
        const alias = 'userUpdateRequiredFields';
        const config = {
          meta: { alias },
        };

        _http.put(alias, data, config).then(
          (answer) => {
            _user.profile = answer.data.result;
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer);
          },
        );

        return defer.promise;
      },

      updateOptionalFields(data) {
        const defer = $q.defer();
        const alias = 'userUpdateOptionalFields';
        const config = {
          meta: { alias },
        };

        _http.put(alias, { update: data }, config).then(
          (answer) => {
            _user.profile = answer.data.result;
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer);
          },
        );

        return defer.promise;
      },

      yearReport() {
        const defer = $q.defer();
        const alias = 'userYearReport';
        const config = {
          meta: { alias },
        };
        _http.get(alias, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          },
        );
        return defer.promise;
      },

      /** Check if it is possible to store user input
       * @param {object} data - user credentials to check
       * @param {string} [data.phone] - phone
       * @param {string} [data.login] - login
       * @param {string} [data.email] - email
       * @return {Promise}
       * */
      validateUserData(data) {
        const defer = $q.defer();
        const alias = 'validateUserData';
        const config = { meta: { alias } };

        _recaptcha
          .execute()
          .then((recaptchaToken) => {
            Object.assign(data, { 'g-recaptcha-response': recaptchaToken });
          })
          .catch((error) => error)
          .finally(() => {
            _http.post(alias, data, config).then(
              ({ data }) => {
                defer.resolve(data);
              },
              ({ data }) => {
                defer.reject(data);
              },
            );
          });

        return defer.promise;
      },

      /** Sends user data to track abandoned registrations
       * @param {object} data
       * @param {string} data.language
       * @param {string} data.email
       * @return {void}
       * */
      collectRegistrationAnalytics(data) {
        const alias = 'collectRegistrationAnalytics';
        beaconAPI.send(alias, data);
      },

      balanceDetails() {
        const defer = $q.defer();
        const alias = 'userBalanceDetails';
        const config = {
          cache: false,
          meta: {
            alias,
          },
        };

        _http
          .get(alias, config)
          .then(({ data }) => defer.resolve(data))
          .catch(({ data }) => defer.reject(data));

        return defer.promise;
      },
    };
  }

  app.factory('account', controller);
})();
