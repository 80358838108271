import '../../../service/domain/system.js';
import '../../../service/helper.js';
import '../../../service/domain/content-buffer.js';
import '../../../service/domain/state.js';
import '../../../service/domain/popup.js';
(function () {
  'use strict';

  controller.$inject = ['$scope', 'system', 'helper', 'contentBuffer', 'state', '$state', 'popup'];

  function controller($scope, _system, _helper, _contentBuffer, _state, $state, _popup) {
    $scope.countries = [];
    $scope.currencyList = [];
    $scope.formData = {};
    $scope.preloader = true;

    function prepareCurrencyList(list) {
      return list.map((i) => {
        return { key: i.code, name, icon: i.symbol };
      });
    }

    _system
      .countries()
      .then((a) => {
        $scope.$watch('formData.country', (newVal, oldVal) => {
          const country = $scope.countries.filter((item) => item.code === newVal)[0] || {};
          $scope.currencyList = prepareCurrencyList(country.currency) || [];
          $scope.formData.currency = $scope.currencyList[0].key;
        });
        $scope.countries = a.result;
        $scope.formData.country = a.default;
        $scope.preloader = false;
      })
      .catch((e) => {})
      .finally(() => {
        $scope.preloader = false;
      });

    $scope.submit = _helper.form.validator((form) => {
      const state = _state.get('registration');
      if (state.page) {
        // todo if page
        //$state.goto( state.name,  )
      } else {
        _popup.open({ name: state.name, content: { formData: $scope.formData } });
      }
    });
  }

  new Controller('fastRegistrationForm', controller);
})();

(function () {
  'use strict';

  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanFastRegistrationForm${i}`, 'fastRegistrationForm');
  });
})();
