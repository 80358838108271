import  user from '../../app/service/domain/user.js'
import  modal from '../../app/service/domain/modal.js'
import  bonus from '../../app/service/domain/bonus.js'

(function() {

  'use strict';

  const directive = { name: 'zetBonusActivation' };

  controller.$inject = ['user', 'modal', 'bonus'];

  function controller(_user, _modal, _bonus){
    function activate($scope, bonus_id) {
      $scope.preloader = true;
      _bonus
        .activate({ bonus_id })
        .then(() => {
          if ($scope.successAlias) {
            _state.goto($scope.successAlias);
          }
          _popup.close();
        })
        .catch(({ messages }) => {
          if (messages[0].code === 6322) {
            _modal.open('bonus-activation-rules', $scope.bonus).catch(() => {});
          }
        })
        .finally(() => {
          $scope.preloader = false;
        });
    }
    function link(scope, element, attrs,){
      element.on('click', () => {
        if (typeof scope.bonus.bonus_id === 'number') {
          if (_user.activeBonus.bonus_id) {
            _modal.open('bonus-activation', scope.bonus).then(
              (a) => {
                activate(scope, scope.bonus.bonus_id);
              },
              (a) => {}
            );
          } else {
            activate(scope, scope.bonus.bonus_id);
          }
        }
      })
    }

    return {
      restrict: 'A',
      link
    };
  }

  app.directive( directive.name, controller );
})();
