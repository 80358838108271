(function () {

    'use strict';

    const directive = {name: 'boomerangCheckedWb'};

    controller.$inject = ['$location'];

    function controller($location) {
      const userAffiliateBonus = $location.search();
        function link(scope, element, attrs) {
            scope.$watchCollection(
                "collection",
                function () {
                  if(userAffiliateBonus.bonus && scope.collection.length){
                    const bonusForUser = scope.collection.filter(bonus => {
                      if(bonus.type === userAffiliateBonus.bonus) return bonus;
                    })
                    if(bonusForUser.length) {
                      scope.formData.trigger_codes = bonusForUser[0].trigger_codes;
                      scope.activeBonus = bonusForUser[0].name;
                      scope.$parent.bonusSelect = bonusForUser[0].type;
                    }
                    return
                  }
                  if (scope.collection.length && scope.collection[0].trigger_codes) {
                      scope.formData.trigger_codes = scope.collection[0].trigger_codes;
                      scope.activeBonus = scope.collection[0].name;
                      scope.$parent.bonusSelect = scope.collection[0].type;
                  }
                }
            );

        }

        return {
            restrict: 'A',
            link
        };
    }

    app.directive(directive.name, controller);

})();
