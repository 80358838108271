var $_CONFIG = $_CONFIG || {};
$_CONFIG.sport = true;
$_CONFIG.sportVersion = 3;
$_CONFIG.sportId = 'boomerang';
$_CONFIG.sportWidgetSkinName = 'boomerang';
$_CONFIG.useSvgSprite = false;
$_CONFIG.scripts = $_CONFIG.scripts || [];
$_CONFIG.domain = 'https://boomerang-casino.devv2.space';
$_CONFIG.siteDomain = 'https://boomerang-casino.com';
$_CONFIG.cdn = 'https://joxi.imgsrcdata.com';
$_CONFIG.siteName = 'boomerang';

$_CONFIG.pwa = true;
$_CONFIG.appName = 'Boomerang';
$_CONFIG.themeColor = '#1E2737';
$_CONFIG.googleAnalytics = { appId: 'UA-151907223-2 ', trackAs: 'boomerang-casino.com ' }

$_CONFIG.symplify = true;

$_CONFIG.customScripts = [];

$_CONFIG.pgw = {enabled: true};

$_CONFIG.licenseId = '80438314-4c74-439c-8d6f-76df7fab37b1';
$_CONFIG.apiVersion = 'v2';
$_CONFIG.language = ['en', 'pl', 'ru', 'de', 'fi', 'hu', 'no', 'it', 'hi', 'cs', 'br', 'fr', 'au'];

$_CONFIG.liveAgent = {
    default: {
        bid: 'i3lsrgf8',
        sid: 'la_x2s6df8d'
    },
    it: {
        bid: 'i3lsrgf8',
        sid: 'la_x2s6df8d'
    },
    de: {
        bid: 'i3lsrgf8',
        sid: 'la_x2s6df8d'
    },
    pl: {
        bid: 'i3lsrgf8',
        sid: 'la_x2s6df8d'
    },
    no: {
        bid: 'i3lsrgf8',
        sid: 'la_x2s6df8d'
    },
    fi: {
        bid: 'i3lsrgf8',
        sid: 'la_x2s6df8d'
    },
    ru: {
        bid: 'i3lsrgf8',
        sid: 'la_x2s6df8d'
    },
    hu: {
        bid: 'i3lsrgf8',
        sid: 'la_x2s6df8d'
    },
    pt: {
        bid: 'i3lsrgf8',
        sid: 'la_x2s6df8d'
    },
    br: {
        bid: 'i3lsrgf8',
        sid: 'la_x2s6df8d'
    }
};



//- ПЛЕЙСИ ДЛЯ БАНЕРІВ І СЛАЙДЕРІВ
//- в шаблоні вказувати саме об'єкт конфіга.
$_CONFIG.places = {
    banner: {
        registration: {
            casino: `'registration_bonus_casino'`,
            sport: `'registration_bonus_sport'`,
            promocode: `'registration_bonus_promocode'`,
            nobonus: `'registration_bonus_no'`,
        },
        popup1: `'welcome'`,
        home1: `'welcome'`,
        burger: `'welcome'`
    },
    slider: {
        home1: `'main_slider'`
    },
    promo: {

    },
    icons: {
        footer1: `'footer_pay'`,
        paynplay: `'paynplay_pay'`
    }
};

$_CONFIG.sportCategory = {
    football: "football",
    tennis: "tennis",
    basketball: "basketball",
    hockey: "hockey"
};

$_CONFIG.banners = {};

if (typeof window !== 'undefined') {
    window.$_REGISTRATION = {};
    window.$_REGISTRATION.promotions = {
        category: 'registration_promo',
        active: 0,
    };
}

$_CONFIG.sportBanners = {
    topCenter: {
        iframe: `/sport-banners-slider/`,
        options: {
            pages: ['prelive'],
            height: '190px'
        }
    },
    aboveHighlights: {
        iframe: '/sport-banners-slider/live-stream/',
        options: {
            pages: ['prelive'],
            height: '290px'
        }
    },
};

export { $_CONFIG };