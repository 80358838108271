(function () {

	'use strict';

	const directive = { name: 'historyBack' };

	controller.$inject = [];

	function controller() {
		function link( scope, element ){
			element.bind('click', function () {
				window.history.back();
			});
		}

		return {
			restrict: 'A',
			link
		};
	}

	app.directive( directive.name, controller );

})();
